/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import React, { ChangeEvent } from 'react';

import { TextInput } from '@sede-x/shell-ds-react-framework';

import { ITextInputProps } from '@sede-x/shell-ds-react-framework/build/esm/components/TextInput/TextInput.types';
import { Controller, UseControllerProps } from 'react-hook-form';

import { IMemoisedComponentProp } from '../../types/Common.type';
import ErrorMessage from '../ErrorMessage';

interface IMinMaxValidation {
  value: number;
  message: string;
}

interface ITextInputControl extends IMemoisedComponentProp {
  controllerProps: UseControllerProps;
  label: string;
  min?: IMinMaxValidation;
  max?: IMinMaxValidation;
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: ITextInputProps;
}

const FormControlTextInput: React.FC<ITextInputControl> = ({
  methods,
  controllerProps,
  label,
  handleOnChange,
  inputProps,
}) => {
  const {
    formState: { errors },
    control,
  } = methods;

  const { name } = controllerProps;

  return (
    <>
      <Controller
        control={control}
        {...controllerProps}
        render={({ field: { onChange, value } }) => (
          <div>
            <span>{label}</span>
            <TextInput
              placeholder={label}
              onChange={(event) => {
                if (handleOnChange) handleOnChange(event);
                onChange(event);
              }}
              value={value}
              invalid={!!errors[name]}
              {...inputProps}
            />
            {errors[name] && errors[name]?.message && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </div>
        )}
      />
    </>
  );
};
export default FormControlTextInput;
