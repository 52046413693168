/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';

import { Menu } from '@sede-x/shell-ds-react-framework';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FullStateI } from '../../types/All.type';
import { getMenuItem } from './utils';

const SideNavMenuContainer = styled.div`
  width: 215px;
  position: absolute;
  right: 0;
  top: 2px;
  overflow-x: hidden;

  .cmt-menu,
  .genesis-standalone-menu {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0;
    font-style: normal;
    :hover {
      background-color: rgba(0, 151, 187, 0.1);
    }
  }
`;

const getMenuClassName = (menupath: string, pathname: string): string => {
  return pathname === menupath ? 'shell-menu-item-selected' : '';
};

const SideNavMenu: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userRolesAndMenu = [] } = useSelector(
    (state: FullStateI) => state.User
  );

  const onSelect = useCallback(
    (e) => {
      const path = e.key;
      if (typeof path === 'string' || path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const getClassName = useCallback(
    (menuPath: string) => {
      return getMenuClassName(menuPath, pathname);
    },
    [pathname]
  );

  return (
    <SideNavMenuContainer>
      <Menu size='medium' onSelect={onSelect}>
        {userRolesAndMenu.map((menu) => getMenuItem(menu, getClassName))}
      </Menu>
    </SideNavMenuContainer>
  );
};

export default SideNavMenu;
