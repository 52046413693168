import { CommonInfoProps } from '../../types/IBT/IBT.type';

export const GET_IBT_COMMON_INFO = `/api/cop/IBTTool/GetIBTDropDownValues?enumCat=IBTTool&api-version=v1`;
export const GET_IBT_MARKETS = `/api/cop/IBTTool/GetMarkets`;
export const GET_MARKET1_DETAILS = `/api/cop/IBTTool/GetMarket1`;
export const GET_IBT_MAPPINGS = `/api/cop/IBTTool/GetIBTMappings`;
export const GET_IBT_BOOKS_PAGINATED = `/api/cop/IBTTool/GetIBTBooksPaginationData`;
export const ADD_UPDATE_IBT_MAPPING = `/api/cop/IBTTool/AddUpdateIBTMapping`;
export const ADD_UPDATE_IBT_BOOK = `/api/cop/IBTTool/AddUpdateIBTBook`;
export const DELETE_IBT_MAPPING = `/api/cop/IBTTool/DeleteIBTBook`;
export const ADD_UPDATE_IBT_TRADING = `/api/cop/IBTTool/AddUpdateIBTTrading`;
export const GET_IBT_BOOKS = `/api/cop/IBTTool/GetIBTBooks`;
export const GET_IBT_TRADERS = `/api/cop/IBTTool/GetIBTTraders`;
export const GET_IBT_MAPPING_BY_MARKET_ID = `/api/cop/IBTTool/GetIBTMappingsByMarketId`;
export const GET_IBT_TRADINGS = `/api/cop/IBTTool/GetIBTTradings`;
export const GET_IBT_TRADE_HOURS = `/api/cop/IBTTool/GetIBTTradeHours`;
export const GET_IBT_TRADING_WITH_PAGINATION = `/api/cop/IBTTool/GetIBTTradingsPaginationData`;
export const GET_IBT_MAPPING_AUDIT_LOG = `/api/cop/IBTTool/GetIBTMappingAuditLog`;
export const GET_IBT_TRADING_LOG = `/api/cop/IBTTool/GetIBTTradingAuditLog`;
export const GET_PAYLOAD_INFO = `/api/cop/IBTTool/GetPayloadInfo`;

export const MESSAGES = {
  SERVER_ERROR: 'Something went wrong please contact system administrator.',
  REQUIRED_MARKET_1: 'Market-1 is required',
  REQUIRED_TRADE_FREQUENCY: 'Trade Frequency is required',
  REQUIRED_CURRENCY: 'Currency is required',
  REQUIRED_COMPONENT_1: 'Component-1 is required',
  REQUIRED_MARKET_2: 'Market-2 is required',
  REQUIRED_COMPONENT_2: 'Component-2 is required',
  REQUIRED_UNIT: 'Unit of Measure is required',
  REQUIRED_TIMESTAMP: 'Timestamp is required',
  REQUIRED_BOOK: 'Book is required',
  REQUIRED_TRADER: 'Trader is required',
  REQUIRED_COUNTERPARTY: 'Counterparty is required',
  REQUIRED_BUY_SELL: 'Buy/Sell is required',
  REQUIRED_MEMO: 'Memo is required',
  REQUIRED_PERIOD: 'Period is required',
  REQUIRED_PERIOD_START_TIME: 'Period Start Time is required',
  REQUIRED_PERIOD_END_TIME: 'Period End Time is required',
  REQUIRED_GAS_DAY: 'Gas Day is required',
  REQUIRED_VOLUME: 'Volume is required',
  REQUIRED_PRICE: 'Price is required',
  INVALID_PRICE: 'Price should be till 3 decimal places',
  REQUIRED_TOTAL_VOLUME: 'Total Volume is required',
  REQUIRED_SETTLEMENT_VALUE: 'Settlement Value is required',
  MAPPING_OVERRIDE:
    'Please note that when you addorupdate mapping, if the fields Deadline, Formula, WD Variance Breach, and DA Variance Breach are present for another mapping with the same Source Production Field, those values will be overridden.',
};

export const TradeFormFields = (commonInfo: CommonInfoProps) => [
  {
    name: 'Market1Id',
    label: 'Market1',
    type: 'select',
    required: MESSAGES.REQUIRED_MARKET_1,
    data: commonInfo.Market1,
    disabled: true,
  },
  {
    name: 'Component1Id',
    label: 'Component1',
    type: 'select',
    required: MESSAGES.REQUIRED_COMPONENT_1,
    data: commonInfo.Component1,
    disabled: true,
  },

  {
    name: 'TradeFrequencyId',
    label: 'Daily/Hourly',
    type: 'select',
    required: MESSAGES.REQUIRED_UNIT,
    data: commonInfo.TradeFrequency,
    disabled: true,
  },

  {
    name: 'UnitId',
    label: 'Unit of Measure',
    type: 'select',
    required: MESSAGES.REQUIRED_UNIT,
    data: commonInfo.Unit,
    disabled: true,
  },
  {
    name: 'volume',
    label: 'Volume',
    type: 'number',
    required: MESSAGES.REQUIRED_VOLUME,
  },
  {
    name: 'totalVolume',
    label: 'Total Volume',
    type: 'number',
    required: MESSAGES.REQUIRED_TOTAL_VOLUME,
    readOnly: true,
  },
  {
    name: 'price',
    label: 'Price',
    type: 'number',
    required: MESSAGES.REQUIRED_PRICE,
  },
  {
    name: 'bookId',
    label: 'Book',
    type: 'select',
    required: MESSAGES.REQUIRED_BOOK,
    data: commonInfo?.Books?.filter((item) => item.visible).map((item) => ({
      id: item.id,
      name: item.name,
    })),
  },
  {
    name: 'counterPartyId',
    label: 'CounterParty',
    type: 'select',
    required: MESSAGES.REQUIRED_COUNTERPARTY,
    data: commonInfo.Books,
  },
  {
    name: 'traderId',
    label: 'Trader',
    type: 'select',
    required: MESSAGES.REQUIRED_TRADER,
    data: commonInfo.Traders,
    disabled: true,
  },
  {
    name: 'buySell',
    label: 'Buy/Sell',
    type: 'select',
    required: MESSAGES.REQUIRED_BUY_SELL,
    data: [
      { id: 1, name: 'BUY' },
      { id: 2, name: 'SELL' },
    ],
  },
  {
    name: 'period',
    label: 'Period (Gas Day)',
    type: 'date-range',
    required: MESSAGES.REQUIRED_PERIOD,
  },
  {
    name: 'periodStartTime',
    label: 'Period Start Time',
    type: 'time',
    required: MESSAGES.REQUIRED_PERIOD_START_TIME,
  },
  {
    name: 'periodEndTime',
    label: 'Period End Time',
    type: 'time',
    required: MESSAGES.REQUIRED_PERIOD_END_TIME,
  },
  {
    name: 'excecutedTimestamp',
    label: 'Executed Timestamp',
    type: 'dateTime',
    required: MESSAGES.REQUIRED_TIMESTAMP,
  },
  {
    name: 'memo1',
    label: 'Memo1',
    type: 'text',
    required: false,
  },
  {
    name: 'settlementValue',
    label: 'Settlement Value',
    type: 'number',
    required: MESSAGES.REQUIRED_SETTLEMENT_VALUE,
    readOnly: true,
  },

  // {
  //   name: 'Currency',
  //   label: 'Currency',
  //   type: 'select',
  //   required: MESSAGES.REQUIRED_UNIT,
  //   data: commonInfo.Currency,
  // },
  // {
  //   name: 'Market2',
  //   label: 'Market2',
  //   type: 'select',
  //   required: MESSAGES.REQUIRED_MARKET_2,
  //   data: commonInfo.Market2,
  // },
  // {
  //   name: 'Component2',
  //   label: 'Component2',
  //   type: 'select',
  //   required: MESSAGES.REQUIRED_COMPONENT_2,
  //   data: commonInfo.Component2,
  // },
  // {
  //   name: 'gasDay',
  //   label: 'Gas Day',
  //   type: 'date',
  //   required: MESSAGES.REQUIRED_GAS_DAY,
  //   inputProps: {
  //     placeholder: 'DD MM YYYY',
  //   },
  // },
];

export const MappingMarketFormFields = (commonInfo: CommonInfoProps) => [
  {
    name: 'market1Id',
    label: 'Market1',
    type: 'select',
    required: MESSAGES.REQUIRED_MARKET_1,
    data: commonInfo.Market1,
  },
  {
    name: 'component1Id',
    label: 'Component1',
    type: 'select',
    required: MESSAGES.REQUIRED_COMPONENT_1,
    data: commonInfo.Component1,
  },
  {
    name: 'market2Id',
    label: 'Market2',
    type: 'select',
    required: MESSAGES.REQUIRED_MARKET_2,
    data: commonInfo.Market2,
  },
  {
    name: 'component2Id',
    label: 'Component2',
    type: 'select',
    required: MESSAGES.REQUIRED_COMPONENT_2,
    data: commonInfo.Component2,
  },
  {
    name: 'unitId',
    label: 'Unit of Measure',
    type: 'select',
    required: MESSAGES.REQUIRED_UNIT,
    data: commonInfo.Unit,
  },
  {
    name: 'tradeFrequencyId',
    label: 'Daily/Hourly',
    type: 'select',
    required: MESSAGES.REQUIRED_TRADE_FREQUENCY,
    data: commonInfo.TradeFrequency,
  },
  {
    name: 'currencyId',
    label: 'Currency',
    type: 'select',
    required: MESSAGES.REQUIRED_CURRENCY,
    data: commonInfo.Currency,
  },
];
